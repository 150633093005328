
.overlay-loader {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  row-gap: 20px;
  
  .loading-icon {
    transform: scale(1.5);
    background: none !important;
  }
  .loading-label {
    font-weight: bold;
    background: #ff5f00;
    color: #fff;
    padding: 5px 15px;
    border-radius: 3px;
  }
}