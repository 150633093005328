@import './src/styles/colors';
@import '../../styles/mixins.scss';

.shimmer-container {
    padding: 0 30px;
    width: 100%;

    .shimmer-table {
        @include cardShadow();

        .shimmer-table-header {
            width: 100%;
            height: 65px;
            border: 1px solid #ebebeb;
            padding: 15px;

            .header-content {
                height: 30px;
            }
        }

        .shimmer-table-row {
            width: 100%;
            height: 170px;
            padding: 15px;
            border: 1px solid #ebebeb;

            .row-content {
                height: 140px;
            }
        }
    }

    .shine {
        animation: placeholderShimmer 2s infinite linear;
        background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
        background-size: 1000px 100%;
        border-radius: 5px;
    }

    @keyframes placeholderShimmer {
        0% {
            background-position: -1000px 0;
        }

        100% {
            background-position: 1000px 0;
        }
    }

    .wrapper {
        animation: fullView 0.5s forwards cubic-bezier(0.250, 0.460, 0.450, 0.940);
        width: 0px;
    }

    @keyframes fullView {
        100% {
            width: 100%;
        }
    }

}