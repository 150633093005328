@import "./src/styles/fonts";

.datePickerFilters {
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 200px;
}

.dateRangeHeading {
    font-family: $robotoCondensed;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 24px;
}


.filterColumn {
    display: flex;
    flex-direction: column;
    margin-right: 16px;
    margin-bottom: 24px;
  
}

.filterColumn label {
    font-family: $robotoCondensed;
    font-size: 16px;
    font-weight: 700;
}

.filterColumn input {
    margin-top: 8px;
    margin-right: 8px;
    padding-right: 2rem;
}

.dateRange {
    font-size: small;
    margin-left: 34px;
}

.custom-radio {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    cursor: pointer;
    font-weight: bold;
    font-size: 1rem;
}

.custom-radio input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    width: 0;
    height: 0;
}

.custom-radio .checkmark {
    position: relative;
    height: 24px;
    width: 24px; 
    background-color: #fff;
    border: 2px solid #ccc;
    border-radius: 50%;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-radio input[type="radio"]:checked ~ .checkmark {
    background-color: #FF5F00;
    border-color: #FF5F00;
}

.custom-radio .checkmark svg {
    display: none;
}

.custom-radio input[type="radio"]:checked ~ .checkmark svg {
    display: block;
}