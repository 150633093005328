// Primary Colors
$white : white;
$black: black;
$black-loader: #1A1A1A;
$orange : #ff5f00;
$sixt-black : #191919;
$green: #4CAF50;
$orange: #fe5f00;
$red: #D32F2F;

// Secondary Colors
$hoverOrange : #e45500;
$disable : #969696;
$grey : #B2B2B2;
$lightGrey:#f2f2f2;
$bgHover : #EBEBEB;
$crossedPrice : #873e00;
$errorState : #d32f2f;
$contentPagesBg : #f8f8f8;