@import './src/styles/colors';

.react-select-container {
    outline: none;

    .react-select__control--is-focused {
        outline: none;
        box-shadow: none;
    }

    .react-select__control {
        border-radius: 0;
        border: 0;
        border-bottom: 1px solid #6c6c6c;
        background-color: transparent;
    }

    .react-select__indicator {
        color: black;
    }

    .react-select__indicator-separator {
        width: 0;
    }

    .react-select__menu {
        border-radius: 0;
        margin: 0 0px 0 1px;
        width: 99.6%;
    }

    .react-select__value-container {
        padding-left: 0;
    }

    .react-select__option--is-selected {
        background-color: $orange !important;
    }

    .react-select__option--is-focused {
        background-color: rgba(255, 95, 0, 0.08);
    }
}