@import "./src/styles/colors";
@import "../../styles/mixins.scss";

.vat-invoice-page {
  margin: 30px 20px;

  .page-title {
    padding: 0;
  }

  .shimmer-container {
    padding: 0;
    .shimmer-table {
      .shimmer-table-row {
        height: 80px;
        .row-content {
          height: 60px;
        }
      }
    }
  }

  .react-bootstrap-table {
    background: white;
    margin: 0;
    border: 0;

    .vat-rides-table {
      margin-bottom: 0;
      thead {
        color: #777;
        text-transform: capitalize;
        tr {
          height: 40px;
          background-color: #ffffff;
          box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.12);
          th {
            border: none;
            padding: 20px 10px 20px 10px;
            // &.sortable {
            //   cursor: pointer;
            // }
          }
        }
      }
      tbody {
        tr {
          // cursor: pointer;
          border-top: 1px solid #ddd;
          td {
            border: 0;
            padding: 25px 10px 25px 10px;
          }
          &:hover {
            background-color: initial;
          }
        }
      }
    }

    .pickup-location,
    .drop-location {
      width: 280px;
    }

    .status-col {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      max-width: 300px;
      .status-text-rejected {
        color: $orange;
      }
      .status-text-approved {
        color: $green;
      }
      .last-comment {
        display: flex;
        column-gap: 5px;
        align-items: center;
        word-break: break-all;
      }
    }

    .invoice-actions {
      .btn_primary {
        background-color: $orange;
        border: none;
        color: #fff;
        border-radius: 0;
        padding: 5px 15px;
        // font-size: 16px;
        text-align: center;
        &:focus {
          outline: none;
        }
      }
    }
  }

  .navigation-footer {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    column-gap: 20px;
    align-items: center;
    .btn {
      color: white;
      background: $orange;
      border-radius: 0;
      outline: none;
      border: none;
    }
  }

  .filter-badges-row {
    margin-bottom: 20px;
    .filter-badges-container {
      margin: 0;
      gap: 10px;
      .badge {
        margin-left: 0;
      }
    }
  }
}

.rejection-comments-container {
  // width: 400px;
  // box-shadow: gba(0, 0, 0, 0.1) 0px 4px 12px;
  .tooltip-inner {
    max-width: 400px;
    background-color: #272727;
    color: #e6e6e6;
    .comments-row {
      width: 100%;
      display: flex;
      column-gap: 10px;
      // margin-bottom: 10px;
      padding: 5px;
      &:not(:last-child) {
        border-bottom: 1px dashed #626262;
      }
      > span {
        text-align: left;
      }
      .updated-time-col {
        width: 70px;
      }
      .comment-status-col {
        width: 100px;
      }
      .comment-note-col {
        width: 150px;
        word-wrap: break-word;
      }
    }
  }
}
