
.text-field-input-container {
  display: flex;
  border: 1px solid #C8C8C8;
  height: 100%;
  align-items: center;
  margin-left: 10px;

  input, .search-icon {
      padding: 5px 10px;
  }
  input {
      border: 0;
      // border-left: 1px solid #C8C8C8;
      border-right: 1px solid #C8C8C8;
      height: 37px;
      color: #191919;
      max-width: 130px;
      font-size: 14px;
      
      &:focus {
          outline: none;
      }
  }
  .search-icon {
      padding-top: 8px;
      cursor: pointer;
      svg {
          height: 20px;
      }
  }
}