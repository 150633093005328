@import './src/styles/colors.scss';

.react-bootstrap-table {
    background: white;
    margin: 0 30px;
    border: 0;

    th,
    td {
        border: 0;
        padding: 15px;
    }

    thead tr {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
        background-color: #fff;
        height: 40px;
    }

    th {
        position: sticky;
        top: 0;
        background: white;
        color: #333;
        outline: none;
        font-size: 14px;
        font-weight: bold;
        line-height: 16px;
        border-bottom: 1px solid #EBEBEB;
        padding-bottom: 16px;

        &.sortable {
            cursor: pointer;
        }

        &.sorting {
            cursor: pointer;
            outline: none;
        }

        &.custom-tooltip {
            cursor: pointer;

            &[data-tooltip]:hover:after {
                content: attr(data-tooltip);
                padding: 10px;
                position: absolute;
                left: 0;
                top: 79%;
                background: #191919;
                color: white;
                display: flex;
                height: fit-content;
                letter-spacing: 0.27px;
                font-size: 12px;
            }
        }
    }

    th:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }

    tbody tr {
        border-top: 1px solid #ddd;

        &:hover {
            background-color: #f5f5f5;
        }
    }

    // columns
    .plain-text {}

    .bold-text {
        font-weight: bold;
    }

    .date-time-status {
        display: flex;
        flex-direction: column;
        font-size: 11px;

        .time {
            font-size: 15px;
        }

        .status-container {

            display: flex;
            flex-direction: column;

            .status {
                margin-top: 4px;

                .icon {
                    margin-right: 5px;
                }

                &.arrived {
                    color: #FFB403;
                }

                &.started {
                    color: #4CAF50;
                }

                &.late {
                    color: #D32F2F;
                }

                &.completed {
                    color: #999999;
                }
            }

            .flight {
                color: #999999;
                margin-top: 4px;
                cursor: pointer;
                width: fit-content;

                .plane {
                    margin-right: 5px;
                }
            }
        }

        .performance {
            font-size: 11px;
            cursor: pointer;
            margin: 5px 0 0 0;

            .car-icon {
                margin: -3px 7px 0 0;
                width: 13px;
            }

            .kpi {
                color: #999999;
            }
        }
    }

    .reservation-details {
        display: flex;
        flex-direction: column;
        font-size: 12px;
        line-height: 16px;
        padding-left: 15px;

        .reservation-id,
        .vehicle,
        .class {
            color: $sixt-black;
            margin-bottom: 3px;
        }
        .ic-ride {
            img {
                margin-right: 5px;
            }
        }
        .hot-ride {
            width: max-content;
            background: $orange;
            color: $white;
            font-weight: bold;
            height: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 2px;
        }
    }

    .pickup-drop {
        display: flex;
        flex-direction: column;
        color: $sixt-black;
        font-size: 12px;
        line-height: 16px;

        .icon {
            margin-right: 10px;
        }

        .flight {
            color: $orange;
            padding: 0 0 5px 0;

            .plane {
                margin-right: 12px;
            }
        }

        .pickup,
        .drop {
            margin-bottom: 8px;
            word-break: break-word;
        }

        .distance {
            color: #999999;
            font-size: 11px;

            .icon {
                margin-right: 15px;
            }
        }

        .duration {
            font-size: 12px;
            color: $sixt-black;

            .icon {
                margin-right: 12px;
            }
        }

    }

    .payout {
        color: $sixt-black;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0.5px;
        line-height: 22px;
    }

    .customer-comments {

        .icon {
            margin: 0 5px 2px 0;
        }

        .customer-name {
            margin-bottom: 4px;
        }

        .comment {
            color: $sixt-black;
            font-size: 13px;
            line-height: 16px;
            word-break: break-all;
            padding-bottom: 12px;
        }

        .no-comments {
            color: #999999;
            margin-bottom: 4px;
            font-size: 13px;
        }

        .other-details {

            .other-details-container {
                display: flex;
                flex-direction: row;
                width: fit-content;
                cursor: pointer;
                max-height: 20px;

                .detail-item {
                    display: flex;
                    margin-right: 20px;
                    color: $orange;

                    .seats,
                    .luggage {
                        cursor: pointer;
                        margin-right: 3px;
                    }
                }
            }
        }
    }

    .driver-vehicle {
        color: $sixt-black;
        font-size: 12px;
        font-weight: bold;
        line-height: 16px;

        .driver {
            margin-bottom: 8px;
        }

        .icon {
            margin-right: 5px;
        }

        .unassigned {
            color: #999999;
        }
    }

    .actions {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .accept {
            background: $orange;
            color: white
        }

        .reject,
        .accept,
        .update {
            height: 25px;
            width: 100%;
            margin-bottom: 4px;
            font-size: 13px;
        }

        .timer {
            color: $orange;
            margin-top: 4px;
            font-size: 15px;
            font-weight: bold;
            text-align: right;
            font-family: "Roboto Condensed";
        }

        &:disabled {

            .reject,
            .accept {
                cursor: not-allowed;
                border: 1px solid #969696;
            }

            .reject {
                color: #969696;
            }

            .accept {
                background: #969696;
            }

            .timer {
                display: none;
            }
        }


    }

    .ride-count {
        font-weight: bold;
    }

    .ride-performance-cell {
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 0.6px;

        .car-icon {
            margin: -3px 10px 0 0;
        }
    }

    .missed-kpi-cell {
        color: $red;
        cursor: pointer;
    }

    .button-outline {
        height: 30px;
        width: 120px;
        border: 1px solid $orange;
        background: none;
        padding-left: 17px;
        padding-right: 17px;
    }

    .react-bs-table-no-data {
        padding-left: 30px;
        text-align: center;
    }
}

.pagination-container {
    text-align: center;

    .pagination {
        li {
            a {
                color: $orange;
            }

            span {
                border-radius: 0;
            }
        }

        .active {
            span {
                background-color: $orange;
                border-color: $orange;
            }
        }
    }
}