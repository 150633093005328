@import "./src/styles/fonts";

.date-range-picker {
  width: auto; 
}

.input-label {
  display: block;
  margin-bottom: 5px;
  margin-left: 24px;
  font-size: 14px;
  color: #333; 
}

.input-container {
  position: relative;
  width: 416px; 
  cursor: pointer;
  margin-left: 24px;
}

.input-field {
  width: 100%;
  height: 50px ;
  cursor: pointer;
  padding: 16px;
  padding-right: 30px; 
  border: 1px solid black;

  border-radius: 4px;
  box-sizing: border-box;
  font-size: medium;
}

.input-field:focus {
  outline: none;
}

.dropdownIconClass {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none; 
  width: 24px; 
}

.calendarViewContainer{
  position: absolute;
  top: 100%;
  left: 0;
  display: flex;
  width: 994px;
  margin-top: 8px;
  z-index: 10;
  flex-direction: column;
  justify-items: flex-end;
  background: white;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.3);
}

.datePickerContainer {
    display: flex;
    margin: 24px;  
}

.filterClass {
  width: 200px
}

.datePicker {
  display: flex;
  position: relative;
  user-select: none;
  gap: 23px;
}

.navButton {
  position: absolute;
  margin-top: 5%;
  top: 0;
  display: none;
  pointer-events: all;
  cursor: pointer;      
  z-index: 10;
}

@media (min-width: 768px) {
  .navButton {
      display: block;
  }
}

.nextButton {
  right: 0;
}

.previousButton {
  left: 0;
}

.buttonContainer{
  display: flex;
  justify-content: flex-end;
  margin-right: 24px;
  margin-bottom: 24px;
}

.buttonClass{
  font-family: $robotoCondensed;
  width: 132px !important;
  height: 56px;
  font-size: 20px;
  background-color: #FF5F00 ;
  border: #FF5F00;
  color: white;
}

.buttonClass:hover {
  background-color: #e05400;
}
