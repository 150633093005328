@import './src/styles/colors';
@import '../../styles/mixins.scss';
@import "./src/styles/fonts";

.header {
    display: flex;
    height: 60px;
    background-color: #191919;
    border-radius: 0px;
    padding: 0 15px;
    color: white;

    .left {
        display: flex;
        padding: 14px 0;

        .menu {
            font-size: 30px;
            cursor: pointer;

            .hamburger {
                margin: 0 15px 10px 5px;
            }
        }

        .logo {
            margin: 0 10px 0 30px;
            align-self: flex-end;
        }

        .cursor-pointer {
            cursor: pointer;
        }

        .title {
            font-size: 24px;
            line-height: 44px;
            margin-right: 10px;
            .admin_header_title{
                position: relative;
                background-color: $red;
                height: 20px;
                width: 45px;
                font-size: 12px;
                font-weight: bold;
                color: $white;
                margin-left: 30px;
                margin-bottom: 64px;
                padding: 3px;
                bottom: 20%;
            }
        }
    }

    .center{
        font-size: 14px;
        font-weight: bold;
        line-height: 16px;
        margin-left: auto;
        display: flex;
        align-items: center;
    }
    .divider {
        height: 21px;
        border-right: 1px solid white;
        margin: 0 20px;
    }

    .right {
        display: flex;
        margin-left: auto;
        align-items: center;

        .icon {
            margin-left: 8px;
            width: 20px;
        }

        .newsletter-link,
        .corona-faq {
            font-size: 14px;
            font-weight: bold;
            line-height: 16px;
            cursor: pointer;

            a {
                color: inherit;
            }
        }

        .corona-faq .icon {
            margin-top: -5px;
            width: 15px;
        }

        .language {
            position: relative;

            img, svg {
                width: 25px;
                cursor: pointer;
            }

            .language-dropdown {
                position: absolute;
                top: 42px;
                margin-left: -16px;
                padding: 0;
                list-style-type: none;
                background: white;
                border: 1px solid #ccc;
                border-top: 0;
                box-shadow: 0 5px 10px 0 rgba(196, 196, 196, 0.5);
                z-index: 1;

                .language-option {
                    display: block;
                    color: #333;
                    font-weight: 400;
                    line-height: 1.42857143;
                    padding: 8px 15px;
                    clear: both;
                    white-space: nowrap;
                    cursor: pointer;
                    min-width: 130px;

                    &:hover,
                    &.active {
                        background: $orange;
                        color: white;
                    }
                }
            }
        }

        .user-name {
            font-size: 14px;
            font-weight: bold;
            line-height: 16px;
        }

        .options {
            display: flex;
            align-items: center;
            margin-left: 10px;
            padding: 10px;
            cursor: pointer;

            &.check-new:after {
                content: '';
                width: 6px;
                height: 6px;
                border-radius: 50%;
                position: relative;
                left: -15px;
                top: -10px;
                background: #d1383d;
            }

            .options-caret {
                margin-left: 5px;
                border-top: 4px dashed;
                border-right: 4px solid transparent;
                border-left: 4px solid transparent;
            }

            .option-dropdown {
                position: absolute;
                background: white;
                padding: 12px 0;
                right: 15px;
                left: auto;
                top: 60px;
                border: 1px solid #ccc;
                border-top: 0;
                @include cardShadow();
                z-index: 101;

                .hide {
                    display: none;
                }

                .option {
                    display: block;
                    color: #333;
                    font-weight: 400;
                    line-height: 1.42857143;
                    padding: 8px 15px;
                    clear: both;
                    white-space: nowrap;
                    cursor: pointer;

                    &.notification {
                        display: flex;
                        align-items: center;

                        .notification-label {
                            margin-right: 25px;
                        }
                    }

                    &.link {
                        padding: 0;

                        a {
                            display: block;

                            padding: 3px 15px;
                            color: #333;
                        }
                    }

                    &:hover,
                    &:hover a {
                        background: $orange;
                        color: white;
                    }
                }
            }
        }
    }
}
.payportBannerButtonClass{
    white-space: nowrap;
    margin-top: 8px;
    overflow: hidden;
    font: $robotoCondensed;
    width: fit-content !important;
}