@import "./src/styles/fonts";

.calendarDayWrapper {
    display: inline-flex;
    flex-basis: 48px;
    position: relative;
    background-color: white;
}
  
.calendarDayAspectRatioBox {
    padding-top: 100%;
    width: 50px;
    position: relative;
}
  
.calendarDay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 37px;
    background-color: white;
    border: none;
    outline: none; 
  }

.calendarDay.selected {
    background-color: #FFEFE5;
}
  
.calendarDay.today{
    color: #FF5F00;
}

.calendarDay.selection-start {
  color: #FFFFFF;
  background-color: #FF5F00;
}

.calendarDay.selection-start::after {
  all: inherit;
  background-color: #FF5F00;
  content: attr(data-text);

}

.calendarDay.selection-end {
  color: #FFFFFF;
  background-color: #FF5F00;
}

.calendarDay.selection-end::after {
  all: inherit;
  background-color:#FF5F00;
}

.calendarDay.selection-start.selection-end::after {
  border-radius: 12px;
}

.calendarDay.today:not(.selection-start):not(.selection-end) {
  border: 2px solid #FF5F00;
  color: #191919;
  font-weight: 700;
  font-family: $robotoCondensed;
  font-size: 14px;
}

.calendarDay.today.selection-end{
    color: #FFFFFF;
    background-color: #FF5F00;
}

.calendarDay:hover:not(.selection-start):not(.selection-end):not(.disabled-style) {
  border: 3px solid #FF5F00;
  background-color:  #FFFFFF;
 
}
 
.calendarDay:hover.today:not(.selection-start):not(.selection-end):not(.disabled-style) {
  border: 3px solid #FF5F00;
}  
  
.calendarDay:active {
  opacity: 0.6;
}  

.calendarDay.in-current-month.disabled-style {
  color: grey;
  cursor: not-allowed;
} 

.calendarDay:focus-visible {
  z-index: 10;
  border-radius: 4px;
  outline: 2px solid #FF5F00;
}
