@import './src/styles/colors.scss';
$container-height: calc(100vh - 60px);
$menu-width: 250px;

.main-container-wrapper,
.dialog {
    display: flex;
    align-items: stretch;
    width: 100%;
    margin-left: 0;

    &.mini-menu {

        .side-menu-container {
            width: 57px;
            transition: width 0.2s linear;

            &:hover {
                width: $menu-width;
            }
        }
    }

    .side-menu-container {
        position: relative;
        width: $menu-width;
        box-shadow: 0 5px 10px 0 rgba(196, 196, 196, 0.5);
    }

    .side-menu-content {
        position: relative;
        width: 100%;
        height: $container-height;
        background-color: #f8f8f8;
    }

    .side-menu-container,
    .side-menu-content {
        padding: 0;
        min-height: $container-height;
    }

    .page-title {
        height: 35px;
        color: $black;
        font-size: 30px;
        line-height: 35px;
        margin: 0;
        padding: 30px;
        margin-bottom: 20px;
    }

    .loading {
        padding: 30px;
        font-size: 20px;
        line-height: 35px;
        min-height: $container-height;
    }

    .filter-container {
        display: flex;
        margin-bottom: 15px;

        .filter-row {
            display: flex;

            .filter {
                display: flex;
                align-self: flex-end;
                align-items: center;
                margin: 0 30px;
                color: $disable;
                font-size: 13px;
                letter-spacing: 0.58px;
                line-height: 16px;
                text-transform: uppercase;
                width: 100%;
                flex-wrap: wrap;
                row-gap: 10px;
                justify-content: flex-start;
            }
        }
    }

    .table-info {
        padding-left: 30px;
        color: $disable;
        font-size: 12px;
    }

    @import './table.scss';
}

#flight-tooltip {
    opacity: 1;

    .tooltip-inner {
        background-color: #191919;
        display: flex;
        flex-direction: column;
        border-radius: 0px;
        text-align: initial;
        min-width: 240px;
        padding: 10px;

        .icon {
            margin-right: 10px;
        }

        .pick-up {
            display: flex;
            flex-direction: column;
            border-bottom: 1px solid #444444;
            padding-bottom: 10px;

            .time-info {
                display: flex;
                font-weight: bold;
                line-height: 16px;
                font-size: 14px;
                margin-bottom: 5px;
                text-transform: uppercase;
                letter-spacing: 0.5px;
                font-family: "Roboto Condensed";
            }

            .pickup-comment {
                line-height: 16px;
                color: #B2B2B2;
                font-size: 12px;
            }
        }

        .flight {
            margin-top: 15px;

            .flight-time-status {
                line-height: 16px;
                letter-spacing: 0.4px;

                &.ON-TIME,
                &.LANDED,
                &.ON.TIME {
                    color: #FFB403;
                }

                &.EARLY {
                    color: #D32F2F;
                }

                &.DELAYED {
                    color: #4CAF50;
                }
            }

            .time {
                line-height: 16px;
                float: right;
            }

            .terminal {
                line-height: 16px;
                color: #B2B2B2;
                font-size: 12px;
            }
        }

        .sponsor {
            margin-top: 15px;
            color: #B2B2B2;

            .update {
                .status {
                    color: white;
                    margin-left: 5px;
                }
            }

            .powered-by {
                font-family: Roboto;
                font-size: 10px;
            }
        }
    }
}

#requirements-tooltip-container {

    &.top {
        opacity: 1;
        margin-top: -20px !important;
    }

    .tooltip-inner {
        background-color: #191919;
        display: flex;
        flex-direction: column;
        border-radius: 0px;
        text-align: initial;
        min-width: 330px;
        padding: 10px;

        h4 {
            font-size: 16px;
            margin-left: 15px;
            text-align: center;
        }

        .list {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            .list-item {
                display: flex;
                flex-direction: column;
                margin: 10px;
                text-align: center;

                .icon {
                    width: 20px;
                    align-self: center;
                }

                .label {
                    font-size: 11px;
                }

                .details {
                    font-size: 11px;
                }
            }
        }
    }
}

#missed-kpi-tooltip {
    font-family: "Roboto Condensed";
    font-size: 14px;

    .tooltip-inner {
        background-color: #191919;
        border-radius: 0px;
        text-align: initial;
        min-width: 250px;
        padding: 10px 15px;

        .title {
            font-weight: bold;
            text-transform: capitalize;
            font-size: 14px;

            .warning-image {
                margin-right: 10px;
            }

        }

        .kpi-list {
            padding-left: 20px;
            list-style-type: none;

            li {
                padding: 3px 0;
            }

            &>li:before {
                content: "-";
                position: absolute;
                margin-left: -15px;
            }
        }
    }
}