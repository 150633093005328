// @import url('https://fonts.googleapis.com/css?family=Roboto+Condensed');

// Variables and mixins
@import './src/styles/colors';
@import './src/styles/common';

:root {
    --star-size: 15px;
    --star-color: #cecece;
    --star-background: #ff5f00;
}

// CSS will render here
body {
    margin: 0;
    padding: 0;
    font-family: "Roboto", sans-serif;
}

.no-gutters {
    margin-right: 0;
    margin-left: 0;
    padding-right: 0;
    padding-left: 0;
}

a {
    color: $orange;

    &:hover,
    &:focus {
        text-decoration: none;
        color: $orange;
    }
}

.hide {
    display: none;
}

.invisible {
    visibility: hidden;
}

.uppercase {
    text-transform: uppercase;
}

.strike-through {
    text-decoration: line-through;
    opacity: 0.5;
    font-style: italic;
}

.bold-text {
    font-weight: bold;
}

.ellipsis {
    /* Add fixed width along with adjacent class */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.green {
    color: $green;
}

.red {
    color: $red;
}

.orange {
    color: $green;
}

.content-status {
    text-align: center;
    padding-top: 20px;
}

.page-content {
    min-height: calc(100vh - 120px);
}

.react-bs-table-csv-btn {
    border-radius: 0;
    // height: 40px;
    background: $orange;
    color: white;
    font-weight: bold;
    border: 0;
    display: block;
    margin: 0 30px 10px auto;

    &:hover {
        background: #ed5900;
        color: white;
    }

    &:active,
    &:focus {
        background: $orange;
        color: white;
    }
}

/* Spinner */
@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

.spinner {
    float: left;
    margin: 2px 0 0 0;
    position: absolute;

    &:before {
        display: none;
        content: '';
        box-sizing: border-box;
        position: relative;
        /* top: 50%;
        right: 15%; */
        width: 15px;
        height: 15px;
        /* margin-top: -10px;
        margin-left: -10px; */
        border-radius: 50%;
        border-top: 2px solid $white;
        animation: spinner .6s linear infinite;
    }

    &.active:before {
        display: inline-block;
    }
}

.mobile-device,
.browser-not-supported {
    background: #dedede;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: fixed;
    height: 100%;
    width: 100%;
    padding: 40px;
    user-select: none;

    .info-image {
        height: 40%;
    }

    .heading {
        font-size: 19px;
    }

    .tagline {
        font-size: 17px;
        text-align: center;

        &.mobile {
            margin-bottom: 35px;
        }
    }



    .redirect {
        text-decoration: underline;
    }

    .links {
        display: flex;
        flex-direction: column;
        text-align: center;
        width: 50%;

        .download-link {

            .playStoreBadge,
            .appStoreBadge {
                width: 100%;
            }
        }
    }
}

.react-joyride__tooltip button {
    outline: none;
}

/* ADDED POST MIGRATING TO react-bootstrap v4 */
.modal-backdrop {
    opacity: 0.5;
}

.tooltip {
    opacity: 1;
    margin-bottom: 8px;

    /* flicker issue on arrow */
    /* .arrow {
        left: 50%;
        top: 23px;
        bottom: 0;
        border: 5px solid transparent;
        border-top-color: #000;
        border-bottom-width: 0;
        margin-left: -5px;
    } */
}


.modal-content .pagination-container {
    text-align: center;

    .pagination {
        display: flex;
        margin: 0 20px 20px;
        justify-content: space-between;

        li {
            a {
                color: $orange;
            }

            span {
                border-radius: 0;
            }
        }

        .active {
            span {
                background-color: $orange;
                border-color: $orange;
            }
        }
    }
}

/* !important here is to override default library value as instructed by library*/
.rmsc {
    --rmsc-main: #4285f4 !important;
    --rmsc-hover: rgba(255, 95, 0, 0.08) !important;
    --rmsc-selected: #fe5f00 !important;
    --rmsc-border: #ccc !important;
    --rmsc-gray: #000000 !important;
    --rmsc-bg: #fff !important;
  }