@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';


.login-container {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.login {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    background-color: #F8F8F8;
    font-family: "Roboto Condensed", sans-serif;

    .login-card {
        display: flex;
        width: 900px;
        height: 612px;
        background-color: #FFFFFF;
        @include cardShadow();

        .login-form {
            display: flex;
            flex-direction: column;
            padding: 60px 60px 0px;

            .error {
                background: #f8d7da;
                border: 1px solid #ffa39e;
                padding: 2px 5px;
                margin-bottom: 20px;
                text-transform: uppercase;
                font-weight: bold;
            }

            .back-button {
                display: inline;
                cursor: pointer;
                text-align: right;

                .back-image {
                    width: 12px;
                    margin-right: 10px;
                }
            }

            .sixt-logo {
                height: 30px;
                width: 75px;
                margin-bottom: 30px;
            }

            h4 {
                margin-bottom: 0px;
                font-size: 20px;
                font-weight: bold;
                line-height: 24px;
            }

            h6 {
                margin-bottom: 40px;
                color: #969696;
                font-size: 14px;
                line-height: 16px;
            }

            .form-group {
                display: flex;
                flex-direction: column;

                .login-label {
                    font-size: 12px;
                    font-weight: bold;
                    line-height: 14px;
                }

                .login-input {
                    border: 0px;
                    border-bottom: 1px solid;
                    outline: none;
                    color: $orange;
                    font-size: 20px;
                    font-weight: bold;
                    letter-spacing: 0.5px;
                    line-height: 27px;
                    margin-bottom: 25px;
                    padding: 5px 0;
                }

                .phone-number-contact {
                    display: flex;
                    margin-top: 5px;

                    .country-container {
                        display: inline-block;
                        margin-right: 15px;
                        height: 35px;
                        width: 80px;

                        .react-select__control {
                            border-radius: 0;
                            border: 0;
                            border-bottom: 1px solid $orange;
                            box-shadow: none;

                            .react-select__value-container,
                            .react-select__single-value {
                                outline: none;
                            }

                            .react-select__indicator-separator,
                            .react-select__dropdown-indicator {
                                display: none;
                            }
                        }

                        .react-select__control--is-disabled {
                            background-color: unset;
                            cursor: default;
                            border-bottom: 1px solid #969696;

                            .react-select__single-value {
                                color: #969696;
                            }
                        }

                        .react-select__single-value {
                            font-size: 20px;
                            color: $orange;
                        }

                        .react-select__menu {
                            width: 200px;
                        }

                        .country-code {
                            outline: none;
                            height: 30px;
                            border-radius: 0;
                            box-shadow: none;
                            border: 0px;
                            background-color: white;
                            color: #191919;
                            font-size: 16px;
                            font-weight: bold;
                            letter-spacing: 0.5px;
                            line-height: 24px;

                            &:disabled {
                                cursor: default;
                                color: $disable;
                            }

                            option {
                                font-size: 14px;
                                font-weight: bold;
                                letter-spacing: 0.5px;
                                line-height: 20px;
                            }
                        }
                    }

                    .phone-number {
                        flex: 1;
                        color: $orange;

                        &:read-only {
                            cursor: default;
                            color: $disable;
                        }
                    }
                }
            }

            .resend {
                margin: -30px 0 20px 0;
                text-align: right;

                .timer {
                    margin-right: 10px;
                    color: $sixt-black;
                    font-family: Roboto;
                    font-size: 12px;
                    line-height: 14px;
                    cursor: default;
                }

                .resend-otp {
                    cursor: pointer;
                    font-family: "Roboto Condensed", sans-serif;
                    font-size: 12px;
                    font-weight: bold;
                    letter-spacing: 0.5px;
                    line-height: 14px;
                    color: $orange;

                    &.disable {
                        color: $disable;
                        cursor: not-allowed;
                    }
                }
            }

            .button-fill {
                width: 100%;
                height: 60px;
                font-size: 20px;
                font-weight: bold;
                letter-spacing: 2px;
                line-height: 24px;
                outline: none;
            }

            .contact-support {
                margin: 0 0 10px;
                margin-top: auto;

                .contact-support-button {
                    color: $orange;
                    background: transparent;
                    border: 0;
                }
            }
        }

        .login-image-container {
            background-image: url('./images/login-car.webp');
        }

        .login-form,
        .login-image-container {
            width: 50%;
        }

        .login-tagline {
            position: relative;
            top: 130px;
            height: 120px;
            width: 307px;
            opacity: 0.9;
            background-color: $orange;
            font-size: 20px;
            font-weight: bold;
            letter-spacing: 1px;
            line-height: 35px;
            padding: 25px 0 0 25px;

            &:after {
                content: " ";
                position: absolute;
                display: block;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: -1;
                background: $orange;
                transform-origin: bottom left;
                -ms-transform: skew(-30deg, 0deg);
                -webkit-transform: skew(-30deg, 0deg);
                transform: skew(-30deg, 0deg);
            }

            .login-tagline-text {
                color: #FFFFFF;
            }
        }
    }

    .link-toadmin{
        padding-bottom: 10px;
    }
}

.contacts-modal {
    margin-top: 10%;

    .modal-header {
        background: $black;
        color: white;

        .close {
            color: $white;
            opacity: 1;
            margin-top: -25px;
        }
    }

    .modal-body {
        padding: 15px 30px;
    }

    .contact-list {
        padding: 0;

        li {
            list-style: none;
            display: flex;
            align-items: center;
            padding: 10px 0;

            .icon {
                width: 24px;
            }

            .contact-details {
                display: flex;
                flex-direction: column;
                flex: 1;
                color: $black;
                margin: 0 30px;

                .country {
                    height: 20px;
                    font-family: "Roboto Condensed", sans-serif;
                    font-size: 16px;
                    font-weight: bold;
                    letter-spacing: 0.4px;
                    line-height: 20px;
                    margin: 3px 0;
                    text-transform: capitalize;
                }

                .number {
                    font-family: Roboto;
                    font-size: 12px;
                    line-height: 16px;
                    margin: 3px 0;
                }
            }
        }

        li:not(:first-child) {
            border-top: 1px solid #E8E8E8;
        }
    }
}

.login-banner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
}