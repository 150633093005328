@import './src/styles/colors.scss';

.side-menu {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    padding: 0px;
    margin: 0px;
    white-space: nowrap;
    height: calc(100vh - 60px);

    .notification {
        display: block;
        background-color: $orange;
        color: white;
        border: 0;
        margin-top: auto;
        padding: 18px;

        .hide {
            display: none;
        }

        span {
            font-size: 15px;
        }

        .icon {
            margin-right: 15px;
            height: 24px;
            width: 24px;
        }

    }

    .menu {
        display: block;
        padding: 18px;
        height: 60px;

        .menu-icon {
            margin-right: 15px;
            height: 24px;
            width: 24px;
            filter: grayscale(1);
            display: inline-block;
            vertical-align: middle;
        }

        .menu-text {
            color: $disable;
            text-transform: uppercase;
            font-size: 14px;
            line-height: 16px;
            display: inline-block;
            vertical-align: middle;
        }

        &.active,
        &:not(.user-details):hover {
            background-color: rgba(255, 95, 0, 0.08);

            & .menu-text {
                color: $orange;
            }

            & .menu-icon {
                filter: grayscale(0);
            }
        }

        /* &.active {
            border-left: 4px solid $orange;
        } */

        .sub-menu {
            list-style-type: none;
            padding: 0px;
            margin: 0px;

            li {
                cursor: pointer;
                padding: 5px 0px 5px 44px;

                .menu-text {
                    color: $disable;
                    text-transform: Capitalize;
                    font-size: 14px;
                    font-weight: bold;
                    line-height: 16px;
                }

                &.active,
                &:hover {
                    .menu-text {
                        color: $orange;
                    }
                }

            }
        }
    }

    /* .download-container {
        bottom: 0;
        position: absolute;
        display: flex;
        flex-direction: column;
        padding: 20px 5px;
        background: #191919;
        box-shadow: 0px -10px 10px 0px rgba(196, 196, 196, 0.5);

        .tagline {
            color: white;
            font-size: 12px;
            margin: -10px 0 10px -5px;
            text-align: center;
        }

        .links {
            display: flex;
            text-align: center;
            margin-left: 10px;

            .download-link {

                .playStoreBadge {
                    width: 95%;
                }

                .appStoreBadge {
                    width: 75%;
                }
            }
        }
    } */

    /* .user-details {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #EBEBEB;
        padding: 20px 5px 20px 20px;
        cursor: default;

        .welcome-text {
            color: $sixt-black;
            font-size: 18px;
            font-weight: bold;
        }

        .address {
            color: $disable;
            font-size: 12px;
            padding-top: 10px;
        }
    } */
}