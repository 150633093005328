.toast-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    height: 38px;
    border-radius: 4px;
    width: 472px;
    margin: 26px auto 8.5px 16px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}
  
.close-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 18px;
    margin-left: 10px;
}