@import "./src/styles/fonts";

:root {
    --SIZE: 48px;
  }
  
  .calendarWeekdaysWrapper {
    position: fixed;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    background-color: white; 
    border-bottom: none ;
    z-index: 2;
  }
  
 
  @media (min-width: 800px) { 
    .calendarWeekdaysWrapper {
      background-color: white;
      z-index: unset;
      padding-bottom: 0;
      position: relative;
      border-bottom: none !important;
    }
  }
  
  .calendarWeekday {
    text-decoration: none;
    display: flex;
    flex-basis: 28px; 
    min-width: 48px;
    border-bottom: none;

  }
  
  .calendarWeekdayAspectRatioBox {
    padding-top: 100%;
    width: 100%;
    position: relative;
  }
  
  .calendarWeekdayAbbreviation {
    font-family: $robotoCondensed;
    font-weight: 700;
    font-size: 14px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }