@import "./src/styles/fonts";

:root {
    --size: 48px;
}

.calendarMonth {
    width: 350px;
    padding: 8px;
    margin: 4px;
    background-color: white;
}

.calendarMonth_MonthTitle {
    font-family: $robotoCondensed;
    display: flex;
    justify-content: center;
    position: sticky;
    margin-top: 20px;
    z-index: 1;
}

.calendarMonth_DayRow {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
  
}

.calendarMonthTextContainer {
    font-size: x-large;
    font-weight: bolder;
    flex-basis: calc(var(--size) * 7);
    row-gap: 16px;
    text-align: center;
}

.horizontalLineClass{
    height: 1px;
    margin-bottom: 10px;
    background-color: #191919;
}

