@import "./src/styles/fonts";

.modalBodyWrapper{
    padding: 0 25px 25px 25px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.modalBodyHeading{
    font-weight: bolder;
    font-size: x-large;
    font-family: $robotoCondensed; 
}

.fileFormatinput{
    display: flex;
    width: 100%;
    gap: 16px;
    height: 56px;
}

.fileFormatClass{
    border: 1px solid black;
    width: 200px;
}

.downloadButtonClass{
    width: 416px;
    font-family: $robotoCondensed;
}

.custom-format-radio {
    position: relative;
    display: flex;
    align-items: center;
    margin: 16px auto 4px 16px;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
}

.custom-format-radio input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    width: 0;
    height: 0;
}

.custom-format-radio .checkmark {
    position: relative;
    height: 24px;
    width: 24px; 
    background-color: #fff;
    border: 2px solid black;
    border-radius: 50%;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-format-radio input[type="radio"]:checked ~ .checkmark {
    background-color: #FF5F00;
    border-color: #FF5F00;
}

.custom-format-radio .checkmark svg {
    display: none;
}

.custom-format-radio input[type="radio"]:checked ~ .checkmark svg {
    display: block;
}

.warningContainer{
    display: flex;
    gap: 8px
  }
  
.warningDescriptionClass{
    font-weight: 400;
    font-size: 14px;
    margin-top: 2px;
}


