@import './src/styles/colors';
@import '../../styles/mixins.scss';

.note-page {
    .note-page-header{
        display: flex;
        justify-content: space-between;
        margin: 20px 30px;
        .note-page-title{
            height: 35px;
            color: $sixt-black;
            font-size: 30px;
            line-height: 35px;
            margin: 0;
        }
        .note-addnew-btn{
            height: 50px;
            width: 220px;
            background-color: $orange;
            border: none;
            outline: none;
            a {
                color: $white;
                font-size: 16px;
                font-weight: bold;
                line-height: 24px;
                text-align: center;
            }
           
        }
    }
    .note-info-table{
        .actions{
            display: flex;
            flex-direction: row;
           .actions_view{
            background-color: $orange;
            color: white;
            width: 90px;
            height: 30px;
            letter-spacing: 0;
            line-height: 24px;
            outline: none;
            border-radius: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border: none;
           }
        }
    }
}