
.vat-invoice-filter {
  display: flex;
  align-items: center;
  column-gap: 20px;

  .filter-item {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }
  margin-bottom: 20px;

  .filter-box-container {
    margin-left: 0;
    min-width: 200px;
    align-items: stretch;
    .dropdown {
      min-width: auto;
      margin: 0;
    }
  }
}