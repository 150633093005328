@import './src/styles/colors';
@import './src/styles/mixins.scss';


.view-modal{
    margin-top: 10%;
    .modal-content{
        
        .modal-header {
            background: black;
            color: white;
            .modal-title{
                display: inline-block;
                font-weight: bold;
                font-size: 18px;
            }
            .close {
                color: white;
                opacity: 1;
            }
        }

        .modal-body {
            margin-top:10px;
            padding: 15px 30px;
            height:max-content;
            min-height: fit-content;
            .note-body{
                width: 100%;
            }
            .category-info-align{
                display: flex;
                flex-direction: row;
                .category-margin{
                    margin-left: 30%;
                }
                .category-info{
                    display: flex;
                    flex-direction: column;
                    
                    .head-value {
                        font-size: 20px;
                        font-weight: bold;
                        line-height: 28px;
                        font-family: "Roboto Condensed";
                    }
    
                    .head-label{
                        font-size: 16px;
                        color: #999999;
                        cursor: pointer;
                        width: fit-content;
                        font-family: "Roboto Condensed";
                    }
                }
            }

            .date, .user-info{
                display: flex;
                flex-direction: column;

                .head-value {
                    font-size: 20px;
                    font-weight: bold;
                    line-height: 28px;
                    font-family: "Roboto Condensed";
                }

                .head-label{
                    font-size: 16px;
                    color: #999999;
                    cursor: pointer;
                    width: fit-content;
                    font-family: "Roboto Condensed";
                }
            }
            .input_container{
                textarea {
                    width: 100%;
                    border: 1px solid #C8C8C8;
                    background-color: #FFFFFF;
                    outline: none;
                    font-size: 17px;
                    letter-spacing: 0.5px;
                    line-height: 27px;
                    margin-bottom: 2px;
                    font-weight: bold;
                    padding: 10px;
                }
                .textarea-label{
                    font-size: 16px;
                    color: #999999;
                    margin-top: 2px;
                    cursor: pointer;
                    width: fit-content;
                    font-family: "Roboto Condensed";
                }
                .fix-size{
                    overflow-y: scroll;
                    resize: none;
                    width: 100%;

                }
            }
        }

        .modal-footer{
            padding: 15px 30px;
            .button_container{
                display: flex;
                justify-content: space-between;
                .btn{
                    width: 150px;
                    height: 50px;
                    font-family: "Roboto Condensed";
                    font-size: 20px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 24px;
                    text-align: center;
                    outline: none;
                    border-radius: 0;
                    padding: 0;
                }
                .cancel {
                    background-color: white;
                    color: $orange;
                    border: 2px solid;
                }
                .active {
                    background-color: $orange;
                    color: white;
                }
            }
        }
    }
}