@import './src/styles/colors';
@import '../../styles/mixins.scss';

.clear-filter {
    display: flex;
    align-items: center;
    padding: 8px;
    text-transform: capitalize;
    margin-left: 10px;
    height: 40px;
    min-width: 90px;
    border: 1px solid #4CAF50;
    color: #4CAF50;
    background-color: #FFFFFF;
    justify-content: space-evenly;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
}