@import './src/styles/colors';
@import '../../styles/mixins.scss';
@import "./src/styles/fonts";

$important: !important;

.reportsWrapper{
    display: flex;
    gap: 204px
}

.invoiceTableWrapper{
   background: white; 
   border: 1px;
   margin: 24px;
   border-radius: 8px;

}
.invoicesHeadingContainer{
    display: flex;
    justify-content: space-between;
    padding:  0 26px;
}

.invoiceHeading{
    font-weight: bold;
    font-family: $robotoCondensed;
    margin-left: 2px;
}

.invoiceHeading{
    font-weight: bold;
    font-family: $robotoCondensed;
    margin-left: 2px;
}

.notFoundWrapper{
    width: auto;
    background-color: #fff;
    margin:  24px;
    display: flex;
    flex-direction: column;
    height: 771px;
    justify-content: center;
    align-items: center;
}

.notFoundFontClass{
    font-size: 14px;
    margin-top: 24px;
    color: #595959;
}
.invoicesSelectedDownload{
    display: flex;
    cursor: pointer;
    font-size: medium;
    margin-top: 10px;
    color: #FF5F00;
    margin-right: 4px;
    opacity: 1;
    transition: opacity 0.2s ease;

    &.disabled {
        cursor: not-allowed;
        opacity: 0.5; 
        pointer-events: none; 
      }
}

.page-title{
    font-family: $robotoCondensed;
}

.downloadIcon{
  margin-top: 20px;
}

.invoices-table{
    height: 120px;
    border: none  ;
    thead{
        tr{
            box-shadow: none #{$important};
           
        }
        th{
            background-color: #f2f2f2 #{$important};
        }
        th::after {
            border-bottom: 0px #{$important};
        }
    }
}

.invoiceNumberTableCell{
    height: 120px;
    vertical-align: middle #{$important} ;
    font-family: $robotoCondensed;
    font-weight: 700;
    font-size: 16px;
}

.invoicesTableColumn {
    font-weight: bold #{$important};
    font-family: $robotoCondensed;
    font-size: 20px #{$important};
    box-shadow: none #{$important};
    height: 56px;
    vertical-align: middle #{$important} ;
}

.invoicesTableCell{
    height: 120px;
    vertical-align: middle #{$important} ;
}

.actionCell {
    color: #FF5F00;
    display: flex;
    font-weight: 700 !important;
    font-size: 14px !important;
    gap: 32px;
}

.downloadLinkWrapper {
    display: inline-block;
    align-items: center;
    
    min-width: 130px;
    max-width: 130px;
    
    cursor: pointer !important;
    
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.downloadLinkWrapper.disabled {
    cursor: not-allowed #{$important}; 
    pointer-events: none; 
    color: #595959; 
}

.invoiceFilters{
    display: flex;
    gap: 16px;
    padding: 16px 0;
    margin-left: 4px;
}

.iconClass{
    margin-top: 8px;
}

.downloadSelectedContainer{
    margin-top: 10px;
    font-weight: bold;
    font-family: $robotoCondensed;  
}

.customCheckboxStyle {
    position: relative;
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
}

.customCheckboxStyle input[type="checkbox"] {
    appearance: none;
    width: 100%;
    height: 100%;
    border: 2px solid #FF5F00; 
    background-color: #fff;
    cursor: pointer;
    margin: 0;
    position: relative;
    z-index: 1;
}

.checkbox-column {
    width: 56px #{$important};
}

.customCheckboxStyle input[type="checkbox"]:checked {
    border-color: #FF5F00; 
    background-color: #fff;
}

.customCheckboxStyle input[type="checkbox"]:not(:checked) {
    border-color: #ccc; 
}

.checkboxCheckmarkStyle {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 20px;
    height: 20px;
    display: none; 
    justify-content: center;
    align-items: center;
    pointer-events: none;
    z-index: 2; 
}

input[type="checkbox"]:checked + .checkboxCheckmarkStyle {
    display: flex; 
}

.customCheckboxStyle input[type="checkbox"]:focus {
    outline: none; 
}

.checkboxCheckmarkStyle svg {
    width: 16px;
    height: 16px;
    stroke: #FF5F00;
    stroke-width: 3px;
    fill: none;
}

.modalClass{
    width: 496px;
    display: flex #{$important};
    align-items: center #{$important};
    justify-content: center #{$important};
}

.custom-modal-header {
    .cross-icon {
      g, path {
        fill: #FF5F00;
      }
    }
}

.invoice-error-action{
    font-size: 14px;
    font-weight: 400;
    color: #A4A4A4;
    margin: 4px;
}

.invoice-error{
    font-size: 16px;
    font-weight: 700;
    margin-top: 24px;
    color: #595959;
    font-family: $robotoCondensed;
}
