@import './src/styles/colors';
@import './src/styles/mixins.scss';

.auth-loader {
    background-color: $black-loader;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .loading {
        border: 0px;
        width: 40px;
        margin-top: 10px;
    }
}