@import "./src/styles/colors";

.vat-invoice-dialog {
  width: 80%;

  .modal-content {
    padding: 20px 30px;
  }

  .modal-container {
    display: flex;
    column-gap: 15px;
    .modal-main {
      flex: 1;
      background: #fcfcfc;
      padding: 20px;
    }
    .invoice-nav-btn {
      display: flex;
      align-items: center;
      .btn-prev {
        transform: rotate(90deg) scale(2.4);
      }
      .btn-next {
        transform: rotate(-90deg) scale(2.4);
      }
      .btn-prev,
      .btn-next {
        cursor: pointer;
        filter: drop-shadow(2px 4px 6px #777);
        &.disabled {
          cursor: not-allowed;
        }
        polygon {
          fill: #777;
        }
      }
    }
  }

  .invoice-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    padding-bottom: 10px;
    border-bottom: 1px solid #dfdfdf;
    h3 {
      margin: 0;
    }
  }

  .modal-body {
    padding: 0;
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    .invoice-section {
      height: 300px;
      overflow-y: scroll;
      display: flex;
      justify-content: space-around;
      border: 1px solid #ccc;
      .react-pdf__Page__canvas {
        margin: 0 auto;
        width: 90% !important;
        height: 100% !important;
      }
    }

    .inovice-modal-footer {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      .btn_primary {
        background-color: $orange;
        border: none;
        color: #fff;
        border-radius: 0;
        width: 100%;
        text-align: center;
        padding: 10px;
        // font-weight: bold;
        text-transform: uppercase;
        &:focus {
          outline: none;
        }
        &.btn-reject {
          background-color: #fff;
          color: $orange;
          border: 1px solid $orange;
        }
      }
      .reject-confirm-btns {
        display: flex;
        align-items: center;
        column-gap: 40px;

        .reject-cancel {
          background-color: #fff;
          color: $orange;
          border: 1px solid $orange;
        }
      }
    }
  }

  .invoice-upload-container {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    &.ongoing-process {
      button {
        pointer-events: none;
        cursor: default;
      }
    }
    .upload-file-btns {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      column-gap: 10px;
      .btn {
        color: $orange;
        background-color: transparent;
        font-weight: bold;
        &:focus {
          outline: none;
        }
        &:active {
          box-shadow: none;
        }
      }
    }
  }
  .invoice-file {
    .no-file-available {
      font-size: 18px;
      font-weight: bold;
      color: #888;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
    .downloading-file {
      display: flex;
      flex-direction: column;
      margin-top: 50px;
      font-size: 16px;
      font-weight: bold;
      color: #888;
      align-items: center;
      .loader {
        border: 10px solid #f3f3f3; /* Light grey */
        border-top: 10px solid #fe5f00; /* Blue */
        border-radius: 50%;
        width: 100px;
        height: 100px;
        animation: spin 2s linear infinite;
      }
    }

    &.invoice-file-upload {
      width: 100%;
      height: 100%;
    }

    .uploaded-file-container {
      display: flex;
      flex-direction: column;
      button {
        align-self: flex-end;
      }
    }

    .upload-input-container {
      width: 100%;
      height: 100%;
      input {
        display: none;
      }
      label {
        text-transform: uppercase;
        cursor: pointer;
        color: #888;
        font-weight: bold;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        .upload-icon {
          width: 20px;
          height: 24px;
          g {
            fill: #888;
          }
        }
      }
    }

    .react-pdf__Document {
      .react-pdf__message {
        &--error {
          .pdf-error {
            display: flex;
            flex-direction: column;
            align-items: center;

            font-size: 17px;
            margin-top: 100px;
            color: #e74c3c;
            svg {
              width: 25px;
              height: 25px;
            }
          }
        }
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  .rejection-reason-container {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }

  .rejection-comment-container {
    display: flex;
    align-items: center;
    column-gap: 50px;
    label {
      color: #888;
      font-size: 13px;
      font-weight: 400;
      margin-bottom: 0;
    }
    input {
      outline: none;
      border: 1px solid grey;
      padding: 5px;
      border-radius: 3px;
      flex: 1;
    }
    .input-field {
      display: flex;
      align-items: center;
      column-gap: 10px;
    }
  }

  .rejection-comment-section {
    display: flex;
    align-items: flex-start;
    column-gap: 10px;
    label {
      color: #888;
      font-size: 13px;
      font-weight: 400;
      margin-bottom: 0;
    }
    .rejection-comments {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      background-color: #fcfcfc;
      .rejection-comment {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding: 0 10px;
        &:not(:last-child) {
          border-bottom: 1px solid #eee;
        }
        &:first-child {
          .comment {
            // color: #ffa39e;
          }
        }
        .comment {
          font-weight: bold;
        }
        .comment-date {
          color: #666;
        }
      }
    }
    .view-more {
      cursor: pointer;
      color: $orange;
    }
  }

  .react-datepicker-wrapper {
    display: block;
    // width: 100%;
    .react-datepicker__input-container {
      width: inherit;
      input {
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 14px;
        padding: 6px 12px;
        height: 34px;
        &:active,
        &:focus {
          outline: none;
        }
      }
    }
  }
  .react-datepicker-popper {
    z-index: 10;
  }
  .react-datepicker {
    // font-size: 12px;
    border-radius: 0;
    border: 1px solid #dfdfdf;
    font-size: 1.2rem;
    display: flex;

    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range {
      background-color: #fe5f00;
      border-radius: 0;
      &:focus {
        outline: none;
      }
    }

    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
      font-size: 1.244rem;
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      width: 2.4rem;
      line-height: 2.4rem;
      margin: 0.266rem;
    }
    .react-datepicker__month-container {
      float: none;
    }
    .react-datepicker__time-container {
      float: none;
      display: flex;
      flex-direction: column;
    }

    .react-datepicker__input-time-container
      .react-datepicker-time__input-container
      .react-datepicker-time__input
      input {
      width: 100px;
    }

    .react-datepicker__input-time-container {
      margin: 5px 0 10px 10px;
    }
  }
}
