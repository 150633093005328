@import './src/styles/colors';
@import '../../styles/mixins.scss';

.filter-badges-container {
    display: flex;
    margin: 15px 0 0 25px;
    padding: 0;
    width: 100%;
    flex-wrap: wrap;
    align-items: flex-end;
    /* flex-direction: row-reverse; */

    .badge {
        display: flex;
        background-color: #ebebeb;
        color: $black;
        font-weight: 400;
        padding: 10px;
        border-radius: 20px;
        margin-left: 5px;


        .badge-text {
            flex-grow: 1;
            margin-right: 10px;
        }

        .close-icon {
            cursor: pointer;
            align-self: flex-end;
        }

    }


}