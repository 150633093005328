@import './colors.scss';

.button {
    border: none;
    border-radius: 0;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    height: 40px;
    width: 180px;
    font-weight: bold;

    &.button-outline {
        background-color: #f8f8f8;
        color: $orange;
        border: 2px solid $orange;
    }

    &.button-fill {
        background-color: $orange;
        color: #f8f8f8;
    }
}

.d-flex {
    display: flex;
    gap: 5px;
}
.d-flex-col {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.justify-start {
    justify-content: flex-start;
}
.justify-end {
    justify-content: flex-end;
}
.justify-center {
    justify-content: center;
}
.justify-between {
    justify-content: space-between;
}
.align-items-start {
    align-items: flex-start;
}
.align-items-end {
    align-items: flex-end;
}
.align-items-center {
    align-items: center;
}
.gap-20{
    gap: 20px;
}
.gap-30{
    gap: 30px;
}
.cp {
    cursor: pointer;
}
.vh-100 {
    min-height: 100vh;
}