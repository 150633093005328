.ride-details {
  display: flex;
  flex-direction: column;
  row-gap: 30px;

  .ride-details-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 3px;

    .item-value {
      font-weight: bold;
      font-size: 16px;
    }
    .item-label {
      color: #888;
      font-size: 13px;
    }
  }
  .ride-details-row {
    display: flex;
    align-items: center;
    column-gap: 70px;
    row-gap: 20px;
    // justify-content: space-between;
    flex-wrap: wrap;
  }

  .ride-details-location-row {
    display: flex;
    align-items: stretch;
    column-gap: 70px;
    row-gap: 20px;
    .ride-details-item {
      flex: 1;
      justify-content: space-between;
    }
  }

  .ride-details-address-row {
    .ride-details-item {
      flex-direction: row;
      justify-content: flex-start;
      column-gap: 10px;
      align-items: center;
    }
  }
}
