@import './src/styles/colors';
@import '../../styles/mixins.scss';

.date-filter-container {
    display: flex;
    align-items: center;
    margin-left: 10px;

    .button-container {
        display: flex;
        border-bottom: 1px solid #DFDFDF;

        .calendar-button {
            flex: 1 1 0px;
            border: 0;
            border-radius: 0;
            color: $orange;
            background: white;
            padding: 10px 0;
            font-size: 13px;

            &.today {
                border-right: 1px solid #DFDFDF;
            }
        }
    }

    .separator {
        padding-left: 10px;
        align-self: center;
    }

    .date-picker-container {
        display: flex;
        flex-direction: column;
        border: 1px solid #C8C8C8;
        margin-left: 10px;
        height: 40px;
        align-items: center;
        background: white;

        .filter-heading {
            display: flex;
            height: 39px;

            .logo {
                padding: 10px;
                border-right: 1px solid #C8C8C8;
            }

            .filter-label {
                display: flex;
                flex-grow: 1;
                height: 40px;
                align-items: center;
                cursor: pointer;
                color: $sixt-black;
                font-size: 13px;
                letter-spacing: 0.58px;
                line-height: 16px;
                padding: 0;

                .dropdown-icon {
                    margin-left: auto;
                }

                .react-datepicker__input-container input {
                    border: 0;
                }

                .react-datepicker-popper {
                    margin: 13px 0 0 -43px;
                }

                .react-datepicker {
                    margin: -11px 0 0 1px;
                    border-radius: 0;
                    border: 1px solid #dfdfdf;
                    font-size: 1.2rem;

                    .react-datepicker__day--keyboard-selected {
                        background-color: transparent;
                        color: inherit;
                        outline: none;
                    }

                    .react-datepicker__header {
                        background-color: #eeeeee;
                        border-bottom: 1px solid #dfdfdf;
                        border-radius: 0px;
                    }

                    .react-datepicker__current-month,
                    .react-datepicker__day-name {
                        color: black;
                    }

                    .react-datepicker__navigation--previous {
                        border-right-color: black;
                        outline: none;
                    }

                    .react-datepicker__navigation--next {
                        outline: none;
                        border-left-color: black;
                    }

                    .react-datepicker__navigation--previous--disabled,
                    .react-datepicker__navigation--previous--disabled:hover {
                        border-right-color: #e6e6e6;
                    }

                    .react-datepicker__navigation--next--disabled,
                    .react-datepicker__navigation--next--disabled:hover {
                        border-left-color: #e6e6e6;
                    }

                    .react-datepicker__day--selected,
                    .react-datepicker__day--in-selecting-range,
                    .react-datepicker__day--in-range,
                    .react-datepicker__month-text--selected,
                    .react-datepicker__month-text--in-selecting-range,
                    .react-datepicker__month-text--in-range,
                    .react-datepicker__quarter-text--selected,
                    .react-datepicker__quarter-text--in-selecting-range,
                    .react-datepicker__quarter-text--in-range {
                        background-color: $orange;
                        border-radius: 0;
                    }

                    .react-datepicker__current-month,
                    .react-datepicker-time__header,
                    .react-datepicker-year-header {
                        font-size: 1.244rem;
                    }

                    .react-datepicker__day-name,
                    .react-datepicker__day,
                    .react-datepicker__time-name {
                        width: 2.4rem;
                        line-height: 2.4rem;
                        margin: 0.266rem;
                    }

                    .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
                        width: 100px;
                    }

                    .react-datepicker__input-time-container {
                        margin: 5px 0 10px 10px;
                    }

                }

                .react-datepicker__triangle {
                    display: none;
                }

                .react-datepicker-wrapper {

                    input {
                        width: 130px;
                        padding: 5px;
                        outline: none;
                        height: 38px;
                        margin-top: -2px;

                        &:focus {
                            border: 1px solid #cacaca;
                        }
                    }
                }
            }
        }
    }
}