.input-wrapper {
    position: relative;
    width: 300px;
  
    .invoice-search-icon {
      position: absolute;
      left: 10px;
      top: 67%;
      transform: translateY(-50%);
      pointer-events: none;
  
      svg {
        width: 18px;
        height: 18px;
        fill: #A4A4A4;
      }
    }
  
    .inputClass {
      height: 50px;
      margin-top: 24px;
      padding-left: 35px; 
      padding-right: 35px; 
      width: 100%;
      border: 1px solid #ccc;
      color: #A4A4A4;
      border-radius: 4px;
      box-sizing: border-box;
        -moz-appearance: textfield;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
  
      &:focus {
        outline-color: #ff5f00;
      }
    }
  
    .clearIcon {
      position: absolute;
      right: 15px;
      top: 69%;
      transform: translateY(-50%);
      cursor: pointer;
      font-size: 18px;
      color: #999;
  
      &:hover {
        color: #ff5f00;
      }
    }
  }