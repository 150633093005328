@import './src/styles/colors.scss';

$heading-font: 15px;
$other-font: 12px;

.sitemap {
    position: relative;
    display: flex;
    width: 100%;
    padding: 10px 30px;
    background-color: $sixt-black;
    bottom: 0;
    margin-top: 30px;

    .column {
        flex-grow: 1;
        flex-basis: 0;
        color: white;
        letter-spacing: 0.03em;

        .heading {
            font-size: $heading-font;
            font-weight: bold;
        }

        .list {
            display: flex;
            flex-direction: column;

            .link {
                font-size: $other-font;
                color: #b8b8b8;
                margin-bottom: 3px;
            }
        }

        .para {
            font-size: $other-font;
            margin-bottom: 3px;
            cursor: pointer;
            color: #b8b8b8;
        }

        .app-links {
            display: flex;
            flex-direction: column;

            .download-link {

                .playStoreBadge,
                .appStoreBadge {
                    width: 120px;
                    margin-bottom: 10px;
                }
            }
        }
    }

    .version {
        position: absolute;
        right: 0;
        color: #b8b8b8;
        bottom: 0;
        padding: 10px 10px;
        font-size: 10px;
    }

}